import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Row, Col, Typography } from 'antd'

import Layout from '../components/layout'
import Head from '../components/head'
import HypothesesWithChart from '../components/hypotheses/hypotheses-with-chart'
import hypothesesData1 from '../data/hypotheses_chart_1.json'

import { CheckOutlined, CloseOutlined } from '@ant-design/icons'

const { Title, Paragraph, Text } = Typography

const OutilDeGestionDeTresorerie = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      avatarSrc: file(relativePath: { eq: "rocketchart_philippe_vanderstigel_cofounder_product_design.png" }) {
        childImageSharp {
          gatsbyImageData(width: 50)
        }
      }
      ctaImage: file(relativePath: { eq: "CTA-article.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      suiviImage: file(relativePath: { eq: "suivi-tresorerie-image-0.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      journalierImage: file(relativePath: { eq: "logiciel-tresorerie-rapide.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      interfaceImage: file(relativePath: { eq: "rocketchart-alternative-agicap.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      rapprochementImage: file(relativePath: { eq: "suivi-tresorerie-image-3.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      websiteImage: file(relativePath: { eq: "website-rocketchart-fonctionnalites.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      agicapLogoImage: file(relativePath: { eq: "logo-agicap.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      rocketchartLogoImage: file(relativePath: { eq: "logo-rocketchart.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      fygrLogoImage: file(relativePath: { eq: "logo-fygr.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      turbopilotLogoImage: file(relativePath: { eq: "logo-turbopilot.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      tarifsImage: file(relativePath: { eq: "formule-et-tarif-RocketChart.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      avisImage: file(relativePath: { eq: "avis-outils-de-tresorerie.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  const coverImage = 'https://i.imgur.com/lgoM8kS.png'
  const description = 'Qu’est-ce qu’un outil de gestion de trésorerie ? Quel est le meilleur outil de gestion de trésorerie ? Pourquoi utiliser un outil dédié et comment le choisir ? Quels sont les tarifs ?'

  return (
    <Layout>
      <Head
        title="Outil de Gestion de Trésorerie"
        description={description}
        canonical="https://rocketchart.co/outil-de-gestion-de-tresorerie/"
        type="article"
        meta={[
          {
            property: 'og:image',
            content: coverImage
          },
          {
            name: 'twitter:image',
            content: coverImage
          }
        ]}
      >
        <script type="text/javascript" src="https://platform-api.sharethis.com/js/sharethis.js#property=5f81cd2f1aeee50012fb7c33&product=sticky-share-buttons" async="async"></script>
      </Head>

      <Row className="blog-content-container space-around-row" type="flex" justify="center">
        <Col xs={24}>
          <a href="https://app.rocketchart.co/signup" target="_blank" rel="noopener noreferrer">
            <Paragraph align='center'>
              <GatsbyImage image={data.ctaImage.childImageSharp.gatsbyImageData} style={{ maxWidth: 650 }} alt="Gérez et anticipez facilement votre trésorerie." />
            </Paragraph>
          </a>
          <Title align='center'>Outil de Gestion de Trésorerie</Title>
          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 50 }}>
            <a href="https://www.linkedin.com/in/philippevdsrocketchart/" target="_blank" rel="noopener noreferrer" className="rc-blog-avatar-container">
              <GatsbyImage image={data.avatarSrc.childImageSharp.gatsbyImageData} alt="Philippe Vanderstigel from RocketChart" className="rc-blog-avatar" />
            </a>
            <div style={{ marginLeft: 10 }}>
              <a href="https://www.linkedin.com/in/philippevdsrocketchart/" target="_blank" rel="noopener noreferrer">
                Philippe Vanderstigel
              </a>
              <br />
              <Text>Mise à jour : 21 avril 2021</Text>
            </div>
          </div>

          <Paragraph>
            La gestion de trésorerie est cruciale pour les entreprises. Le dirigeant et son équipe financière - DAF, Expert-Comptable, assistant(e) de direction - peuvent s’équiper d’un outil de gestion de trésorerie, autrement appelé <a href="https://rocketchart.co/blog/logiciel-tresorerie/" target="_blank" rel="noopener noreferrer">logiciel de trésorerie</a>, pour piloter efficacement la trésorerie de l’entreprise. Un bon outil favorise une meilleure visibilité et une meilleure anticipation, ce qui permet d’agir vite en cas de difficultés financières.
          </Paragraph>

        </Col>
      </Row>

      <span className='border-2 border-dashed rounded border-gray-200 w-full lg:w-96 my-16' />

      <Row className="content-container" type="flex" justify="center">
        <Col xs={24} lg={14}>
          <Title level={3} className='text-center'>Prenez les <span className='rc-highlight-text'>meilleures décisions</span> avec un outil de gestion de trésorerie.</Title>
        </Col>
      </Row>
      <HypothesesWithChart jsonData={hypothesesData1} className="content-container mt-8" />

      <span className='border-2 border-dashed rounded border-gray-200 w-full lg:w-96 my-16' />

      <Row className="blog-content-container" type="flex" justify="center">
        <Col xs={24} style={{ marginBottom: 100 }}>
          <Paragraph>
            Voici notre guide complet sur les outils de gestion de trésorerie.
          </Paragraph>

          <Paragraph>
            Sommaire :
            <ul>
              <li style={{ listStyleType: 'disc' }}><a href="#menu1">Qu’est-ce qu’un outil de gestion de trésorerie ?</a></li>
              <li style={{ listStyleType: 'disc', marginLeft: '40px' }}><a href="#menu11">Outil de gestion de trésorerie pour TPE</a></li>
              <li style={{ listStyleType: 'disc', marginLeft: '40px' }}><a href="#menu12">Outil de gestion de trésorerie pour PME</a></li>
              <li style={{ listStyleType: 'disc' }}><a href="#menu2">Pourquoi utiliser un outil de gestion de trésorerie ?</a></li>
              <li style={{ listStyleType: 'disc' }}><a href="#menu3">Comment choisir un outil de gestion de trésorerie ?</a></li>
              <li style={{ listStyleType: 'disc' }}><a href="#menu4">Quel est le meilleur outil de gestion de trésorerie ?</a></li>
              <li style={{ listStyleType: 'disc' }}><a href="#menu5">Comparatif des outils de trésorerie</a></li>
              <li style={{ listStyleType: 'disc' }}><a href="#menu6">Tarifs des outils de gestion de trésorerie</a></li>
              <li style={{ listStyleType: 'disc' }}><a href="#menu7">Avis sur les outils de gestion de trésorerie</a></li>
              <li style={{ listStyleType: 'disc' }}><a href="#menu8">La mission de RocketChart</a></li>
            </ul>
          </Paragraph>

          <Title level={2} id='menu1'>Qu’est-ce qu’un outil de gestion de trésorerie ?</Title>

          <Paragraph>
            Avant de démarrer, petit rappel : la trésorerie correspond à l’argent disponible en banque. Elle permet de payer les charges de l’entreprise et de financer son développement. Autant dire qu’elle est vitale pour une société. Un suivi précis et fiable permet de conserver une vue à jour de sa situation financière. Les prévisions de trésorerie permettent quant à elles d’anticiper et de prendre des décisions pour résoudre un potentiel manque de trésorerie.
          </Paragraph>

          <Paragraph>
            Les indicateurs clés d’une bonne gestion de trésorerie sont :
          </Paragraph>

          <Paragraph>
            <ul>
              <li style={{ listStyleType: 'disc' }}>le solde de trésorerie actuel</li>
              <li style={{ listStyleType: 'disc' }}>le solde de trésorerie prévisionnel</li>
              <li style={{ listStyleType: 'disc' }}>les délais et créances clients</li>
              <li style={{ listStyleType: 'disc' }}>les délais et dettes fournisseurs</li>
            </ul>
          </Paragraph>

          <Paragraph>
            Un outil de gestion de trésorerie a pour objectif de faciliter le suivi, la gestion et la prévision de sa trésorerie. Contrairement à Excel qui est très manuel à utiliser et à tenir à jour, l’outil de trésorerie automatise le rapprochement bancaire (connexion avec les banques) et permet de réaliser des projections en quelques clics. C’est un outil indispensable à la gestion financière d’une entreprise.
          </Paragraph>

          <Paragraph align='center'>
            <GatsbyImage image={data.suiviImage.childImageSharp.gatsbyImageData} style={{ maxWidth: 650 }} alt="Suivi de la trésorerie dans un outil de gestion de trésorerie" />
          </Paragraph>

          <Paragraph id='menu11'>
            L’outil de gestion de trésorerie peut-être utilisé par le dirigeant qui obtient alors 100% de visibilité sur sa trésorerie. Mais aussi par les Directeurs Financiers, Expert-Comptable ou Assistant de direction qui gagnent ainsi du temps dans leurs tâches quotidiennes. La mise à jour des tableaux de trésorerie est en temps réel, le rapprochement entre les opérations et les factures est facilité, les prévisions de trésorerie sont fiables et la comparaison entre le budget prévisionnel et le réalisé est automatique.
          </Paragraph>

          <Title level={3}>Outil de gestion de trésorerie pour TPE</Title>

          <Paragraph>
            Les TPE (Très Petites Entreprises) désignent en France les entreprises qui réalisent moins de 2 millions d’euros de chiffres d’affaires et qui emploient moins de 10 salariés. On compte 3 millions de TPE sur le territoire français, soit 95,5% des entreprises (source : INSEE).
          </Paragraph>

          <Paragraph>
            De par leur nature, les TPE ne disposent pas de beaucoup de liquidité sur leur compte bancaire. La trésorerie est souvent en flux tendu. Le dirigeant de TPE réalise souvent la gestion administrative et financière lui-même, en plus de piloter son activité. La gestion de trésorerie doit être rigoureuse et régulièrement mise à jour afin de garantir une bonne visibilité.
          </Paragraph>

          <Paragraph>
            Mais le dirigeant manque de temps et les solutions comme Excel sont chronophages et laborieuses à utiliser. S’équiper d’un outil de gestion de trésorerie pour TPE est indispensable pour se faciliter la vie.
          </Paragraph>

          <Paragraph>
            Les principaux bénéfices sont :
          </Paragraph>

          <Paragraph>
            <ul>
              <li style={{ listStyleType: 'disc' }}>gagner du temps</li>
              <li style={{ listStyleType: 'disc' }}>gagner de la visibilité</li>
              <li style={{ listStyleType: 'disc' }}>augmenter sa rentabilité</li>
              <li style={{ listStyleType: 'disc' }}>éviter les mauvaises surprises</li>
            </ul>
          </Paragraph>

          <Paragraph align='center'>
            <GatsbyImage image={data.journalierImage.childImageSharp.gatsbyImageData} style={{ maxWidth: 650 }} alt="Plus de visibilité sur sa trésorerie" />
          </Paragraph>

          <Paragraph id='menu12'>
            Le dirigeant peut alors se concentrer sur son activité et piloter sereinement son entreprise. Il conserve un oeil avisé sur sa trésorerie, qui est mise à jour en temps réel, et il anticipe le moindre problème. Les demandes de financements sont facilités puisque les données sont exportables. Il peut ainsi les inclure dans ses tableaux financiers.
          </Paragraph>

          <Title level={3}>Outil de gestion de trésorerie pour PME</Title>

          <Paragraph>
            Les PME (Petites et Moyennes Entreprises) correspondent aux entreprises de moins de 250 salariés qui réalisent un chiffre d’affaires inférieur à 50 millions d’euros. Elles sont environ 140 000 en France et constituent 4,4% des entreprises françaises.
          </Paragraph>

          <Paragraph>
            La gestion de trésorerie d’une PME est complexe puisque le nombre de flux à traiter est généralement conséquent. L’entreprise dispose souvent de comptes dans plusieurs banques, ce qui complexifie l’obtention d’une vue consolidée de la trésorerie.
          </Paragraph>

          <Paragraph>
            Un outil de gestion de trésorerie pour PME permet de synchroniser en temps réel et sur une même interface l’ensemble des flux financiers de l’entreprise. La personne chargée de la gestion financière peut rentrer facilement les budgets de trésorerie afin d’ajuster les prévisions. La connexion avec les outils comptables ou de facturation favorise la centralisation des données pour faciliter le suivi et le pointage des factures.
          </Paragraph>

          <Paragraph align='center'>
            <GatsbyImage image={data.interfaceImage.childImageSharp.gatsbyImageData} style={{ maxWidth: 650 }} alt="Outil de gestion de trésorerie RocketChart" />
          </Paragraph>

          <Paragraph id='menu2'>
            Avec un outil adapté et fiable, l’entreprise peut se développer sereinement en conservant une excellente visibilité sur ces liquidités. Les rapports hebdomadaires ou journaliers sont grandement facilités par le partage des accès. L’ensemble des interlocuteurs accède à la donnée en temps réel.
          </Paragraph>

          <Title level={2}>Pourquoi utiliser un outil de gestion de trésorerie ?</Title>

          <Paragraph>
            La fiabilité des données est cruciale dans l’analyse de sa trésorerie. Aussi, la vision à l’instant T de sa trésorerie est tout aussi importante afin d’anticiper correctement et baser son analyse sur des informations à jour. Pour parvenir à un état fiable et à jour de sa trésorerie, les entreprises dépensent beaucoup de temps et d’énergie.
          </Paragraph>

          <Paragraph>
            Un tableau Excel est généralement en place dans l’entreprise. Il est donc mis à jour quand les équipes ou que le dirigeant à le temps de s’y consacrer. Les relevés bancaires sont exportés, les factures payées sont pointées, les budgets sont mis à jour. Toutes ses tâches sont essentielles au bon fonctionnement de l’entreprise. Pourquoi utiliser un outil de gestion de trésorerie si Excel permet déjà de faire le travail ?
          </Paragraph>

          <Paragraph>
            Car ce travail est manuel, chronophage, et il est impossible d’avoir une bonne visibilité sans un suivi rigoureux et régulier. Les erreurs de saisie arrivent très souvent sur des tableurs Excel. Les formules cassent sans s’en rendre compte, on ne se souvient plus de la formule initiale, ni pourquoi on a rentré cette formule.
          </Paragraph>

          <Paragraph>
            Les outils de trésorerie automatisent complètement le suivi et la prévision de trésorerie. Les informations sont constamment à jour grâce à la synchronisation bancaire. Les factures sont rapprochées à chaque paiement. Les prévisions s’ajustent en fonction de la réalité de l’activité. Bref, le dirigeant conserve en temps réel une vue fiable et à jour de l’état de sa trésorerie. Sans aucun effort. L’entreprise est pilotée sereinement.
          </Paragraph>

          <Paragraph align='center' id='menu3'>
            <GatsbyImage image={data.rapprochementImage.childImageSharp.gatsbyImageData} style={{ maxWidth: 650 }} alt="Rapprochement bancaire facture automatisé" />
          </Paragraph>

          <Title level={2}>Comment choisir un outil de gestion de trésorerie ?</Title>

          <Paragraph>
            Choisir le bon outil de gestion de trésorerie pour son entreprise est essentiel : vous gagnerez en temps précieux ainsi qu’une bonne visibilité. Le choix de votre outil de trésorerie va dépendre des fonctionnalités souhaitées, de votre budget, de la taille de votre entreprise, du service attendu.
          </Paragraph>

          <Paragraph>
            Selon la taille de votre structure, vos besoins seront différents : la gestion d’une TPE dans le bâtiment n’est pas la même qu’une PME de développement informatique. Vous devrez donc lister vos besoins. Ensuite, la majorité des éditeurs présentent les fonctionnalités de l’outil directement depuis leur site internet. Vous aurez ainsi une bonne vue des principales features et voir si vos besoins sont couverts. Un support commercial est généralement joignable depuis un live chat si vous avez des questions précises.
          </Paragraph>

          <Paragraph>
            Un exemple des principales fonctionnalités disponibles sur notre site :
          </Paragraph>

          <Paragraph align='center'>
            <GatsbyImage image={data.websiteImage.childImageSharp.gatsbyImageData} style={{ maxWidth: 750 }} alt="Les fonctionnalités de l'outil de trésorerie RocketChart" />
          </Paragraph>

          <Paragraph>
            Le budget que vous souhaitez allouer à ce service va dépendre de la valeur que vous percevez. Certains dirigeants sont prêts à mettre 20€ /mois dans un outil dédié, alors que pour d’autres 100€ /mois est largement acceptable. Encore une fois, cela dépend de votre besoin. Si la gestion actuelle de votre trésorerie est douloureuse, vous devriez allouer un budget plus élevé.
          </Paragraph>

          <Paragraph id='menu4'>
            Enfin, les services auxquels vous accédez dépendent des éditeurs. Certains outils de trésorerie sont 100% cloud (en mode SaaS) alors que d’autres doivent être installés sur l'ordinateur. L’accès au service client est aussi différent, selon l’éditeur et selon l’offre à laquelle vous souscrivez. Les pages tarifs des sites internet devraient vous permettre d’y voir clair, et n’hésitez pas à demander un devis si nécessaire.
          </Paragraph>

          <Title level={2}>Quel est le meilleur outil de gestion de trésorerie ?</Title>

          <Paragraph>
            Le meilleur outil de gestion de trésorerie dépend de la typologie de votre entreprise et de vos besoins. Les avis sur Google et sur les comparateurs peuvent vous aider à identifier le meilleur outil. Mais rien ne sera plus efficace que de tester par vous-même. Généralement les éditeurs proposent des périodes d’essai gratuites pour se familiariser avec l’outil de gestion de trésorerie et vérifier qu’il répond bien à vos besoins.
          </Paragraph>

          <Paragraph>
            Si vous êtes une ETI, les outils comme Kyriba devraient vous aider dans le pilotage de la trésorerie. Pour les TPE et PME, plusieurs outils sont disponibles sur le marché, à tous les prix : RocketChart, Agicap, Fygr, Sage, QuickBooks, etc. Testez-les et retenez celui qui répond le mieux à vos besoins.
          </Paragraph>

          <Paragraph>
            Le meilleur outil de gestion de trésorerie devra être fiable, le plus automatisé possible, intuitif, flexible et personnalisable. Il devra disposer des fonctionnalités dont vous avez besoin. Tout en restant dans le budget que vous vous êtes fixé.
          </Paragraph>

          <a href="https://app.rocketchart.co/signup" target="_blank" rel="noopener noreferrer">
            <Paragraph align='center' id='menu5'>
              <GatsbyImage image={data.ctaImage.childImageSharp.gatsbyImageData} style={{ maxWidth: 650 }} alt="Gérez et anticipez facilement votre trésorerie." />
            </Paragraph>
          </a>

          <br />
          <br />

          <Title level={2}>Comparatif des outils de gestion de trésorerie</Title>

          <Paragraph>
            Pour faciliter votre prise de décision et vous aider dans votre choix, vous pouvez vous appuyer sur les comparatifs des outils de gestion de trésorerie disponibles sur internet. Ils sont accessibles sur les comparateurs en ligne ou sur les sites des éditeurs. Ils prennent en compte différents critères, allant des fonctionnalités, aux services annexes, en passant par des screenshots et le tarif.
          </Paragraph>

          <Paragraph>
            Voici notre comparatif de 4 outils de gestion de trésorerie. Nous reprenons les principaux outils du marché à destination des TPE et PME. Pour chaque outil, vous trouverez les principales fonctionnalités, les avantages, les inconvénients et le tarif.
          </Paragraph>

          <br />
          <br />

          <Row className="content-container" gutter={[40, 20]} style={{ fontSize: 14 }}>
            <Col xs={{ offset: 8, span: 4 }} align='center'>
              <GatsbyImage image={data.agicapLogoImage.childImageSharp.gatsbyImageData} alt="logo Agicap" style={{ maxHeight: 20 }} imgStyle={{ objectFit: 'contain' }} />
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <GatsbyImage image={data.rocketchartLogoImage.childImageSharp.gatsbyImageData} alt="logo RocketChart" style={{ maxHeight: 30 }} imgStyle={{ objectFit: 'contain' }} />
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <GatsbyImage image={data.fygrLogoImage.childImageSharp.gatsbyImageData} alt="logo Fygr" style={{ maxHeight: 20 }} imgStyle={{ objectFit: 'contain' }} />
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <GatsbyImage image={data.turbopilotLogoImage.childImageSharp.gatsbyImageData} alt="logo Turbopilot" style={{ maxHeight: 20 }} imgStyle={{ objectFit: 'contain' }} />
            </Col>

            <Col xs={{ span: 8 }}>
              Date de création
            </Col>
            <Col xs={{ span: 4 }} align='center' className='rc-bold-text'>
              2016
            </Col>
            <Col xs={{ span: 4 }} align='center' className='rc-bold-text'>
              2020
            </Col>
            <Col xs={{ span: 4 }} align='center' className='rc-bold-text'>
              2020
            </Col>
            <Col xs={{ span: 4 }} align='center' className='rc-bold-text'>
              2019
            </Col>
            <Col xs={{ span: 8 }}>
              Montant total des levées de fonds
            </Col>
            <Col xs={{ span: 4 }} align='center' className='rc-bold-text'>
              15M€
            </Col>
            <Col xs={{ span: 4 }} align='center' className='rc-bold-text'>
              0€
            </Col>
            <Col xs={{ span: 4 }} align='center' className='rc-bold-text'>
              400k€
            </Col>
            <Col xs={{ span: 4 }} align='center' className='rc-bold-text'>
              0€
            </Col>

            <br />
            <br />
            <br />
            <br />

            <Col xs={{ span: 8 }}>
              Synchronisation multi-comptes bancaires
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <CheckOutlined className='rc-large-text rc-green-text' />
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <CheckOutlined className='rc-large-text rc-green-text' />
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <CheckOutlined className='rc-large-text rc-green-text' />
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <CheckOutlined className='rc-large-text rc-green-text' />
            </Col>

            <Col xs={{ span: 8 }}>
              Catégorisation automatique des opérations
              </Col>
            <Col xs={{ span: 4 }} align='center'>
              <CheckOutlined className='rc-large-text rc-green-text' />
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <CheckOutlined className='rc-large-text rc-green-text' />
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <CheckOutlined className='rc-large-text rc-green-text' />
            </Col>
            <Col xs={{ span: 4 }} align='center' className='rc-bold-text'>
              Règles manuelles
            </Col>

            <Col xs={{ span: 8 }}>
              Budgets prévisionnels
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <CheckOutlined className='rc-large-text rc-green-text' />
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <CheckOutlined className='rc-large-text rc-green-text' />
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <CheckOutlined className='rc-large-text rc-green-text' />
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <CheckOutlined className='rc-large-text rc-green-text' />
            </Col>

            <Col xs={{ span: 8 }}>
              Comparaison Réalisé vs Budgets prévisionnels
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <CheckOutlined className='rc-large-text rc-green-text' />
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <CheckOutlined className='rc-large-text rc-green-text' />
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <CheckOutlined className='rc-large-text rc-green-text' />
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <CheckOutlined className='rc-large-text rc-green-text' />
            </Col>

            <Col xs={{ span: 8 }}>
              Trésorerie prévisionnelle
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <CheckOutlined className='rc-large-text rc-green-text' />
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <CheckOutlined className='rc-large-text rc-green-text' />
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <CheckOutlined className='rc-large-text rc-green-text' />
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <CheckOutlined className='rc-large-text rc-green-text' />
            </Col>

            <Col xs={{ span: 8 }}>
              Multi-scénarios prévisionnels
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <CheckOutlined className='rc-large-text rc-green-text' />
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <CheckOutlined className='rc-large-text rc-green-text' />
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <CheckOutlined className='rc-large-text rc-green-text' />
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <CheckOutlined className='rc-large-text rc-green-text' />
            </Col>

            <Col xs={{ span: 8 }}>
              Calculs de TVA automatisé
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <CheckOutlined className='rc-large-text rc-green-text' />
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <CheckOutlined className='rc-large-text rc-green-text' />
            </Col>
            <Col xs={{ span: 4 }} align='center' className='rc-bold-text'>
              Formules manuelles
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <CloseOutlined className='rc-large-text rc-red-text' />
            </Col>
            <Col xs={{ span: 8 }}>
              Export et reporting
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <CheckOutlined className='rc-large-text rc-green-text' />
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <CheckOutlined className='rc-large-text rc-green-text' />
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <CheckOutlined className='rc-large-text rc-green-text' />
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <CheckOutlined className='rc-large-text rc-green-text' />
            </Col>

            <Col xs={{ span: 8 }}>
              Multi-entités
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <CheckOutlined className='rc-large-text rc-green-text' />
            </Col>
            <Col xs={{ span: 4 }} align='center' className='rc-bold-text'>
              Non centralisé dans 1 compte
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <CheckOutlined className='rc-large-text rc-green-text' />
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <CheckOutlined className='rc-large-text rc-green-text' />
            </Col>

            <Col xs={{ span: 8 }}>
              Multi-devises
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <CheckOutlined className='rc-large-text rc-green-text' />
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <CloseOutlined className='rc-large-text rc-red-text' />
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <CheckOutlined className='rc-large-text rc-green-text' />
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <CheckOutlined className='rc-large-text rc-green-text' />
            </Col>

            <Col xs={{ span: 8 }}>
              Multi-lingues
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <CheckOutlined className='rc-large-text rc-green-text' />
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <CloseOutlined className='rc-large-text rc-red-text' />
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <CloseOutlined className='rc-large-text rc-red-text' />
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <CloseOutlined className='rc-large-text rc-red-text' />
            </Col>

            <br />
            <br />
            <br />
            <br />

            <Col xs={{ span: 8 }}>
              Pays couverts
            </Col>
            <Col xs={{ span: 4 }} align='center' className='rc-bold-text'>
              France, Belgique, Allemagne, Italie, Espagne
            </Col>
            <Col xs={{ span: 4 }} align='center' className='rc-bold-text'>
              France, Belgique
            </Col>
            <Col xs={{ span: 4 }} align='center' className='rc-bold-text'>
              France, Belgique
            </Col>
            <Col xs={{ span: 4 }} align='center' className='rc-bold-text'>
              France, Belgique
            </Col>

            <Col xs={{ span: 8 }}>
              Support (live chat et email)
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <CheckOutlined className='rc-large-text rc-green-text' />
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <CheckOutlined className='rc-large-text rc-green-text' />
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <CheckOutlined className='rc-large-text rc-green-text' />
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <CheckOutlined className='rc-large-text rc-green-text' />
            </Col>

            <Col xs={{ span: 8 }}>
              Formation obligatoire payante
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <CloseOutlined className='rc-large-text rc-red-text' />
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <CloseOutlined className='rc-large-text rc-red-text' />
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <CloseOutlined className='rc-large-text rc-red-text' />
            </Col>
            <Col xs={{ span: 4 }} align='center' className='rc-bold-text'>
              1188€HT
            </Col>

            <Col xs={{ span: 8 }}>
              Période d’essai gratuite
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <CheckOutlined className='rc-large-text rc-green-text' />
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <CheckOutlined className='rc-large-text rc-green-text' />
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <CheckOutlined className='rc-large-text rc-green-text' />
            </Col>
            <Col xs={{ span: 4 }} align='center'>
              <CheckOutlined className='rc-large-text rc-green-text' />
            </Col>
          </Row>

          <Paragraph id='menu6'>
            Les comparatifs peuvent vous guider dans votre choix et vous faire découvrir de nouvelles solutions, mais ils ne doivent pas altérer votre propre jugement. C’est à vous d’identifier l’outil qui répond le mieux à vos besoins.
          </Paragraph>

          <Title level={2}>Tarifs des outils de gestion de trésorerie</Title>

          <Paragraph>
            3 facteurs principaux influencent le prix de l’outil de gestion de trésorerie :
          </Paragraph>

          <Paragraph>
            <ul>
              <li style={{ listStyleType: 'disc' }}>le positionnement de l’outil dans le marché (pour les artisans, pour les TPE, pour les PME, pour les Groupes, etc.)gagner de la visibilité</li>
              <li style={{ listStyleType: 'disc' }}>les fonctionnalités disponibles dans la version souhaitée</li>
              <li style={{ listStyleType: 'disc' }}>les services qui accompagnent le produit (accompagnement, formation, conseils, support client)</li>
            </ul>
          </Paragraph>

          <Paragraph>
            Si l’outil est positionné sur le marché des PME, les budgets disponibles des entreprises pour ce genre d’outil est forcément plus élevé que pour un artisan ou une TPE. Les besoins sont souvent plus complexes, et le travail de développement technique plus important. Le tarif de l’outil sera donc naturellement plus élevé. Attention donc à choisir un outil de gestion de trésorerie adapté à votre taille d’entreprise pour éviter d’être sur-facturé.
          </Paragraph>

          <Paragraph>
            Aussi, plus vous avez besoin de fonctionnalités avancées, plus le tarif de l’outil de trésorerie sera élevé. En effet, pour un même éditeur les plans tarifaires augmentent en même temps que le nombre de fonctionnalités disponibles dans la version.
          </Paragraph>

          <Paragraph>
            Enfin, certains éditeurs vous imposent un accompagnement, une formation ou un suivi obligatoire. Même si cette prestation n’est pas explicitement inscrite, elle est comprise dans les offres proposées. Renseignez-vous donc sur les services inclus dans les offres.
          </Paragraph>

          <Paragraph>
            Parfois, les éditeurs d’outil de trésorerie préfèrent masquer leur grille tarifaire pour des raisons qui leurs sont propres. Les conditions particulières (période d’essai gratuit, engagement ou non, etc.) ne sont pas toujours visibles. Pensez à vous renseigner sur ces conditions lorsque vous demandez un devis.
          </Paragraph>

          <Paragraph>
            Avec RocketChart par exemple (vous êtes actuellement sur notre site), nous avons choisi d’afficher nos prix et nos conditions en toute transparence : vous les trouverez sur l’onglet “Tarifs” dans le menu en haut de notre site.
          </Paragraph>

          <Paragraph>
            En résumé :
          </Paragraph>

          <a href="https://app.rocketchart.co/signup" target="_blank" rel="noopener noreferrer">
            <Paragraph align='center' id='menu7'>
              <GatsbyImage image={data.tarifsImage.childImageSharp.gatsbyImageData} style={{ maxWidth: 450 }} alt="Gérez et anticipez facilement votre trésorerie." />
            </Paragraph>
          </a>

          <Title level={2}>Avis sur les outils de gestion de trésorerie</Title>

          <Paragraph>
            En plus des comparatifs disponibles en ligne, consulter les avis des internautes est un bon moyen d’éviter les mauvaises surprises. Vous pouvez consulter les avis sur les outils de gestion de trésorerie directement sur les sites des éditeurs.
          </Paragraph>

          <Paragraph>
            Vous trouverez aussi des avis sur les comparateurs en ligne comme <a href="https://www.appvizer.fr/finance-comptabilite/tresorerie/rocketchart#reviews" target="_blank" rel="noopener noreferrer">Appvizer</a> ou le <a href="https://www.ccistore.fr/produit/2646766/rocketchart#prodComment" target="_blank" rel="noopener noreferrer">CCI Store</a>. Une bonne pratique est de croiser les différents sites afin d’avoir une assez bonne vue sur les avis des utilisateurs des outils.
          </Paragraph>

          <Paragraph>
            Vous trouverez aussi les avis des utilisateurs directement sur Google, à droite de votre recherche :
          </Paragraph>

          <Paragraph align='center'>
            <GatsbyImage image={data.avisImage.childImageSharp.gatsbyImageData} style={{ maxWidth: 750 }} alt="Avis outils de gestion de trésorerie" />
          </Paragraph>

          <Title level={2}>Conclusion</Title>

          <Paragraph>
            Les outils de gestion de trésorerie permettent aux entreprises de gagner en visibilité avec une économie de temps fabuleuse. Plutôt que d’effectuer des tâches manuelles sur un tableau, la donnée est déjà traitée. Le dirigeant n’a plus qu’à analyser et interpréter les informations pour piloter son entreprise efficacement. S’équiper d’un logiciel métier est donc indispensable.
          </Paragraph>

          <Paragraph>
            La diversité des outils sur le marché offre aux entreprises un large choix. Chaque outil dispose de ses atouts et inconvénients. Selon les besoins de l’entreprise, qu’elle soit une TPE ou une PME, un outil sera plus pertinent qu’un autre. Les comparateurs et les périodes de test guident les entreprises dans leurs décisions.
          </Paragraph>

          <Paragraph>
            Sur le même sujet :
          </Paragraph>

          <Paragraph>
            <ul>
              <li style={{ listStyleType: 'disc' }}><a href="https://rocketchart.co/blog/logiciel-tresorerie/">Logiciels de trésorerie</a></li>
              <li style={{ listStyleType: 'disc' }}><a href="https://rocketchart.co/solution-de-gestion-de-tresorerie/">Solutions de gestion de trésorerie</a></li>
            </ul>
          </Paragraph>

          <Title level={2}>La mission de RocketChart</Title>

          <Paragraph>
            Notre objectif est de proposer le meilleur <a href="https://rocketchart.co/" target="_blank" rel="noopener noreferrer">outil de gestion de trésorerie</a> pour aider les dirigeants de TPE et PME à piloter sereinement leur activité. Créé en mars 2020, nous accompagnons des centaines d'entreprises au quotidien. Elles automatisent leur suivi, anticipent leur trésorerie et créent différents scénarios prévisionnels pour prendre les bonnes décisions.
          </Paragraph>

          <a href="https://app.rocketchart.co/signup" target="_blank" rel="noopener noreferrer">
            <Paragraph align='center'>
              <GatsbyImage image={data.ctaImage.childImageSharp.gatsbyImageData} style={{ maxWidth: 650 }} alt="Gérez et anticipez facilement votre trésorerie." />
            </Paragraph>
          </a>

        </Col>
      </Row>
    </Layout>
  )
}

export default OutilDeGestionDeTresorerie
